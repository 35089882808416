import React from "react";
// import Layout from '../components/Layout'

const NotFound = () => {
  return (
    <div className="NotFoundContainer">
      <h2>404 Error</h2>
      <p>
        Ooops... <br />
        Page not found
      </p>
    </div>
  );
};

export default NotFound;
